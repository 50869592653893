import {
    IBridgeSelect,
    QUESTION_FIELD_IDENTIFIER,
    QuestionType,
    TenantRegion,
    findRelatedField,
    getEntityField,
    getQuestionFieldFromSelect,
} from "@sp-crm/core";
import { graphqlFetcher } from "backend/fetcher";
import {
    AdvancedSearchCondition,
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchResultSummary,
    BridgeEntityResult,
    BridgeFieldResult,
} from "generated/graphql";
import { produce } from "immer";
import { useQuery } from "react-query";
import { useRegions } from "store/selectors/hooks";
import { stringHash } from "util/generic";

interface AdvancedSearchSelect {
    isRoot: boolean;
    key: string;
    fields: (string | AdvancedSearchSelect)[];
    includeAnswers: boolean;
}

export const fieldPathToMetadata = (
    query: string[],
    root: BridgeEntityResult,
    entityMetadataList: BridgeEntityResult[],
): BridgeFieldResult => {
    if (query.length === 1) {
        const result = getEntityField(root, query[0]);
        if (!result) {
            throw new Error(`Could not find field ${query[0]}`);
        }
        return result;
    }
    const relation = root.relations.find(r => r.name === query[0]);
    if (!relation) {
        throw new Error(`Could not find relation ${query[0]}`);
    }
    const otherEntity = entityMetadataList.find(e => e.name === relation.otherEntityName);
    if (!otherEntity) {
        throw new Error(`Could not find entity ${relation.otherEntityName}`);
    }
    return fieldPathToMetadata(query.slice(1), otherEntity, entityMetadataList);
};

const answersFragment = `
answers {
    questionId
    booleanAnswer
    numberAnswer
    numberAnswerHigh
    textAnswer
    dateAnswer
    calendarDateAnswer
    selections
    idReferenceAnswer
    selectionAnnotations {
        text
        selectionId
    }
}
`;

const customListItemFields = `{
    id
    key
    name
}`;

const advancedSearchSelectToGql = (
    select: AdvancedSearchSelect,
    entityMetadata: BridgeEntityResult,
    entityMetadataList: BridgeEntityResult[],
): string => {
    if (select.isRoot) {
        return select.fields
            .map(f => {
                if (typeof f === "string") {
                    const field = getEntityField(entityMetadata, f);

                    if (field?.questionType === QuestionType.customList) {
                        return `${field.name} ${customListItemFields}`;
                    }

                    return field?.name || "";
                }
                return advancedSearchSelectToGql(f, entityMetadata, entityMetadataList);
            })
            .join("\n");
    }
    const relation = entityMetadata.relations.find(r => r.name === select.key);
    const otherEntity = entityMetadataList.find(
        e => e.name === relation?.otherEntityName,
    );
    if (!otherEntity) {
        console.warn(
            `Could not find entity ${relation?.otherEntityName} for relation ${select.key} from ${entityMetadata.name}`,
        );
        return "";
    }
    entityMetadata = otherEntity;
    return `${select.key} { ${entityMetadata.identifierFields.join("\n")} ${select.fields
        .map(f => {
            if (typeof f === "string") {
                const field = getEntityField(entityMetadata, f);

                if (field?.questionType === QuestionType.customList) {
                    return `${field.name} ${customListItemFields}`;
                }

                return field?.name || "";
            }
            return advancedSearchSelectToGql(f, entityMetadata, entityMetadataList);
        })
        .join("\n")}
        ${select.includeAnswers ? answersFragment : ""} }`;
};

export const makeAdvancedSearchDocument = (
    entityMetadata: BridgeEntityResult,
    select: IBridgeSelect[],
    entityMetadataList: BridgeEntityResult[],
) => {
    const root = buildSelectTree(select, entityMetadata, entityMetadataList);

    return `
    query advancedSearch($params: AdvancedSearchRequest!) {
        advancedSearch(params: $params) {
            page
            pageSize
            total
            summaries {
                groups {
                    groupKey {
                        textValue
                        formattedValue
                        appLink
                    }
                    aggregates {
                        numberValue
                        textValue
                        dateValue
                        formattedValue
                    }
                }
                totals {
                    numberValue
                    textValue
                    dateValue
                    formattedValue
                }
                groupTotal
            }
            entities {
                ... on ${entityMetadata.name} {
                    __typename
                    appLink
                    ${entityMetadata.identifierFields.join("\n                     ")}
                    ${
                        entityMetadata.allowAnswers ||
                        entityMetadata.name === "Client" ||
                        entityMetadata.name === "Community"
                            ? answersFragment
                            : ""
                    }
                    ${advancedSearchSelectToGql(root, entityMetadata, entityMetadataList)}
                }
            }
        }
    }
`;
};

export const makeGetEntityByIdDocument = (
    entityMetadata: BridgeEntityResult,
    select: IBridgeSelect[],
    entityMetadataList: BridgeEntityResult[],
) => {
    const root = buildSelectTree(select, entityMetadata, entityMetadataList);

    return `
    query getEntityById($entityType: AdvancedSearchEntityType!, $entityId: AnswerEntityId!, $select: [AdvancedSearchSelectInput!]!) {
        getEntityById(entityType: $entityType, entityId: $entityId, select: $select) {
            entity {
                ... on ${entityMetadata.name} {
                    __typename
                    appLink
                    ${entityMetadata.identifierFields.join("\n                 ")}
                    ${
                        entityMetadata.allowAnswers ||
                        entityMetadata.name === "Client" ||
                        entityMetadata.name === "Community"
                            ? answersFragment
                            : ""
                    }
                    ${advancedSearchSelectToGql(root, entityMetadata, entityMetadataList)}
                }
            }
        }
    }`;
};

const buildSelectTree = (
    select: IBridgeSelect[],
    entityMetadata: BridgeEntityResult,
    entityMetadataList: BridgeEntityResult[],
) => {
    const root: AdvancedSearchSelect = {
        isRoot: true,
        key: "_",
        fields: [],
        includeAnswers: false,
    };
    const fields = new Set<string>();
    select.forEach(s => {
        if (s.fieldName) {
            const questionField = getQuestionFieldFromSelect(
                s,
                entityMetadata,
                entityMetadataList,
            );
            if (questionField) {
                fields.add(s.fieldName);
            } else {
                const relatedField = findRelatedField(
                    s.fieldName,
                    entityMetadata,
                    entityMetadataList,
                );

                if (relatedField?.metadata?.name) {
                    const rootPath = s.fieldName.split(".");
                    rootPath.pop();
                    const field =
                        rootPath.length > 0
                            ? `${rootPath.join(".")}.${relatedField.metadata.name}`
                            : relatedField.metadata.name;
                    fields.add(field);
                }

                if (relatedField?.metadata?.dependsOn) {
                    const rootPath = s.fieldName.split(".");
                    rootPath.pop();
                    const rootField = rootPath.join(".");

                    relatedField.metadata.dependsOn.forEach(d => {
                        if (rootField) {
                            fields.add(`${rootField}.${d}`);
                        } else {
                            fields.add(d);
                        }
                    });
                }
            }
        }
    });
    fields.forEach(f => {
        const segments = f.split(".");
        let current: AdvancedSearchSelect = root;
        segments.forEach((s, i) => {
            if (i === segments.length - 1) {
                if (s.startsWith(`${QUESTION_FIELD_IDENTIFIER}:`)) {
                    current.includeAnswers = true;
                } else {
                    current.fields.push(s);
                }
            } else {
                const existing = current.fields.find(
                    f => typeof f !== "string" && f.key === s,
                );
                if (existing) {
                    current = existing as AdvancedSearchSelect;
                } else {
                    const newSelect: AdvancedSearchSelect = {
                        isRoot: false,
                        key: s,
                        fields: [],
                        includeAnswers: false,
                    };
                    current.fields.push(newSelect);
                    current = newSelect;
                }
            }
        });
    });

    return root;
};

const updateRegionIdsToCommunityRegionIds = (
    condition: AdvancedSearchCondition,
    regions: TenantRegion[],
) => {
    switch (condition.nodeType) {
        case AdvancedSearchConditionNodeType.And:
        case AdvancedSearchConditionNodeType.Or:
            if (Array.isArray(condition.children)) {
                condition.children.forEach(c => {
                    updateRegionIdsToCommunityRegionIds(c, regions);
                });
            }
            return;
        case AdvancedSearchConditionNodeType.FieldCondition:
            if (condition.fieldName === "regionId" && Array.isArray(regions)) {
                const region = regions.find(r => r.id === condition.textValue);
                if (region) {
                    condition.textValue = region.communityRegionId;
                }
            }
            return;
    }
};

const updateRegionIdsToReferenceRegionIds = (
    condition: AdvancedSearchCondition,
    regions: TenantRegion[],
) => {
    switch (condition.nodeType) {
        case AdvancedSearchConditionNodeType.And:
        case AdvancedSearchConditionNodeType.Or:
            if (Array.isArray(condition.children)) {
                condition.children.forEach(c => {
                    updateRegionIdsToReferenceRegionIds(c, regions);
                });
            }
            return;
        case AdvancedSearchConditionNodeType.FieldCondition:
            if (condition.fieldName === "regionId" && Array.isArray(regions)) {
                const region = regions.find(r => r.id === condition.textValue);
                if (region) {
                    condition.textValue = region.referenceRegionId;
                }
            }
            return;
    }
};

const ensureCorrectRegionIds = (
    request: AdvancedSearchRequest,
    regions: TenantRegion[],
): AdvancedSearchRequest => {
    switch (request.entityType) {
        case AdvancedSearchEntityType.Community:
        case AdvancedSearchEntityType.CommunityContact:
            return produce(request, draft => {
                updateRegionIdsToCommunityRegionIds(draft.condition, regions);
            });
        case AdvancedSearchEntityType.ReferenceBusiness:
        case AdvancedSearchEntityType.ReferenceContact:
            return produce(request, draft => {
                updateRegionIdsToReferenceRegionIds(draft.condition, regions);
            });
        default:
            return request;
    }
};

export interface DynamicAdvancedSearchQuery {
    advancedSearch: {
        page: number;
        pageSize: number;
        total: number;
        entities: Record<string, unknown>[];
        summaries?: AdvancedSearchResultSummary[] | null;
    };
}

export const useAdvancedSearchQuery = (
    request: AdvancedSearchRequest,
    entityMetadataList: BridgeEntityResult[],
) => {
    const regions = useRegions();
    const correctRequest = ensureCorrectRegionIds(request, regions);

    const entityMetadata = entityMetadataList.find(e => e.name === request.entityType);

    const searchDocument = entityMetadata
        ? makeAdvancedSearchDocument(entityMetadata, request.select, entityMetadataList)
        : "";
    const query = useQuery<DynamicAdvancedSearchQuery>(
        [
            "advancedSearch",
            { params: correctRequest, documentHash: stringHash(searchDocument) },
        ],
        graphqlFetcher<DynamicAdvancedSearchQuery, { params: AdvancedSearchRequest }>(
            searchDocument,
            {
                params: correctRequest,
            },
        ),
        { enabled: !!searchDocument, keepPreviousData: true },
    );

    return query;
};
